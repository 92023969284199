<template>
    <div></div>
</template>
<script>
import '@libs/leaflet_plug/LinearMeasurement/Leaflet.LinearMeasurement.css';
import '@libs/leaflet_plug/LinearMeasurement/Leaflet.LinearMeasurement.js';
('use strict');
export default {
    name: 'measure-distance',
    props: {
        basicMap: {
            type: Object,
            default: ''
        }
    },
    data () {
        return {};
    },
    computed: {},
    mounted () {
        this.$nextTick(() => {
            this.measureInit();
        });
    },
    methods: {
        measureInit () {
            let cost_underground = 12.55;
            let cost_above_ground = 17.89;
            let myMap = this.basicMap;
            let html = [
                '<table>',
                ' <tr><td class="cost_label">Cost Above Ground:</td><td class="cost_value">${total_above_ground}</td></tr>',
                ' <tr><td class="cost_label">Cost Underground:</td><td class="cost_value">${total_underground}</td></tr>',
                '</table>'
            ].join('');
            let numberWithCommas = function (x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            };
            let Ruler = L.Control.LinearMeasurement.extend({
                layerSelected: function (e) {
                    let distance = e.total.scalar;
                    if (e.total.unit === 'mi') {
                        distance *= e.sub_unit;
                    } else if (e.total.unit === 'km') {
                        distance *= 3280.84;
                    } else if (e.total.unit === 'm') {
                        distance *= 3.28084;
                    }
                    let data = {
                        total_above_ground: numberWithCommas(L.Util.formatNum(cost_above_ground * distance, 2)),
                        total_underground: numberWithCommas(L.Util.formatNum(cost_underground * distance, 2))
                    };
                }
            });
            myMap.addControl(
                new Ruler({
                    unitSystem: 'metric',
                    color: '#FF0080'
                })
            );
        }
    }
};
</script>
<style scoped></style>
